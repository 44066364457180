<script setup>
import { GrTag } from "@/components/GrComponents";
import { computed } from "vue";
import { useStore } from "@/utils/useStore";
import { useI18n } from "@/utils/useI18n";
import { formatDate } from "@/utils/useDateFormat";

const store = useStore();
const i18n = useI18n();

const getOpportunitySelected = computed(
  () => store.getters["opportunities/getOpportunitySelected"]
);

function getStatusText(status) {
  const texts = {
    WON: i18n.t("opportunities.status.WON"),
    IN_PROGRESS: i18n.t("opportunities.status.IN_PROGRESS"),
    LOST: i18n.t("opportunities.status.LOST"),
    NOT_APPLICABLE: i18n.t("opportunities.status.NOT_APPLICABLE"),
    PENDING: i18n.t("opportunities.status.PENDING"),
  };
  return texts[status] || status;
}

function getStatusVariant(status) {
  const variants = {
    WON: "success",
    IN_PROGRESS: "info",
    LOST: "danger",
    NOT_APPLICABLE: "warning-light",
    PENDING: "danger",
  };
  return variants[status] || "warning-light";
}
</script>

<template>
  <section>
    <p>
      {{ $t("opportunities.details.lastUpdate") }}
      {{ formatDate(getOpportunitySelected.updatedAt) }}
    </p>

    <GrTag class="tag" :variant="getStatusVariant(getOpportunitySelected.status)">
      {{ getStatusText(getOpportunitySelected.status) }}
    </GrTag>
  </section>
</template>

<style scoped lang="scss">
section {
  display: grid;
  gap: 12px;
  margin: 20px 0 30px 0;

  p {
    font-size: var(--font-md);
    font-weight: var(--weight-normal);
    color: var(--gray-200);
  }
}
</style>
