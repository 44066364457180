import moment from "moment";
import i18n from "@/i18n.js";

export function formatDate(dateAt) {
  const date = moment(dateAt);
  const now = moment();

  const diffInDays = now.diff(date, "days");

  const formattedDate = date.format(i18n.t("locale_date.formats.shortYear"));
  const formattedTime = date.format(i18n.t("dateFormat.hoursFormat"));

  if (diffInDays === 0) return `${i18n.t("dateFormat.daysAgo.today")} ${formattedTime}`;

  if (diffInDays === 1) return `${i18n.t("dateFormat.daysAgo.oneDay")} ${formattedTime}`;

  const daysAgoText = `${diffInDays} ${i18n.t("dateFormat.daysAgo.multipleDays")}`;
  return `${daysAgoText} ${formattedDate}`;
}
