<script setup>
import { useStore } from "@/utils/useStore";
import { formatDate } from "@/utils/useDateFormat";
import { computed } from "vue";
import { GrTag } from "@/components/GrComponents";

import startOpportunityIcon from "@/assets/img/icons/opportunities/start-opportunity.svg";
import winIcon from "@/assets/img/icons/opportunities/win.svg";
import lostIcon from "@/assets/img/icons/opportunities/lost.svg";
import pendingIcon from "@/assets/img/icons/opportunities/pending.svg";
import notApplicable from "@/assets/img/icons/opportunities/not-applicable.svg";

import { useI18n } from "@/utils/useI18n";

const i18n = useI18n();
const store = useStore();

const getTimeline = computed(
  () => store.getters["opportunities/getOpportunitySelected"]?.timelines
);

function descriptionTimeline(status) {
  const texts = {
    WON: i18n.t("opportunities.details.timeline.descriptions.WON"),
    IN_PROGRESS: i18n.t("opportunities.details.timeline.descriptions.IN_PROGRESS"),
    LOST: i18n.t("opportunities.details.timeline.descriptions.LOST"),
    NOT_APPLICABLE: i18n.t("opportunities.details.timeline.descriptions.NOT_APPLICABLE"),
    PENDING: i18n.t("opportunities.details.timeline.descriptions.PENDING"),
  };
  return texts[status] || status;
}

function getStatusVariant(status) {
  const variants = {
    WON: "success",
    IN_PROGRESS: "info",
    LOST: "danger",
    NOT_APPLICABLE: "warning-light",
    PENDING: "info",
  };
  return variants[status] || "warning-light";
}

function getIconSrc(type) {
  const images = {
    IN_PROGRESS: startOpportunityIcon,
    WON: winIcon,
    LOST: lostIcon,
    NOT_APPLICABLE: notApplicable,
    PENDING: pendingIcon,
  };

  return images[type] || "";
}
</script>

<template>
  <section>
    <ul v-for="(item, index) in getTimeline" :key="index" class="item">
      <li>
        <GrTag class="tag" :variant="getStatusVariant(item.type)">
          <img :src="getIconSrc(item.type)" />
        </GrTag>
        <div class="data">
          <h3>{{ descriptionTimeline(item.type) }}</h3>
          <span>{{ formatDate(item.updated_at) }}</span>
        </div>
      </li>

      <div class="separator" />
    </ul>
  </section>
</template>

<style scoped lang="scss">
section {
  margin: 20px 0 40px 0;

  display: grid;

  .item {
    display: grid;
    li {
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;

      .tag {
        width: 60px;
        height: 62px;

        border-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .data {
        display: grid;
        gap: 10px;

        h3 {
          font-size: var(--font-md);
          font-weight: var(--weight-semibold);
          color: var(--text);
        }
        span {
          font-size: var(--font-sm);
          font-weight: var(--weight-normal);
          color: var(--gray-200);
        }
      }
    }

    .separator {
      height: 1px;
      width: 100%;
      margin: 20px 0;

      width: 520px;
      margin-left: -30px;
      background-color: var(--gray-50);

      @include size(md) {
        width: 700px;
      }
    }

    &:last-of-type .separator {
      display: none;
    }
  }
}
</style>
